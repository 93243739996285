import {Await, Link, createFileRoute, defer} from '@tanstack/react-router';

import {Input} from '@/components/ui/input';
import {Tag} from '@/components/ui/tag';
import {graphql} from '@/gql';
import {useViewTransitionNavigate} from '@/hooks/use-view-transition-navigation';
import {useRef} from 'react';
import {MdClose, MdSearch} from 'react-icons/md';

export const Route = createFileRoute('/search-preview')({
  component: SearchPreviewPage,
  loader: ({context}) => {
    const deferredClipRecentSearchKeywords = context.apolloClient.query({
      query: graphql(/* GraphQL */ `
        query SearchPreviewPageQuery {
          clipRecentSearchKeywords
        }
      `),
      fetchPolicy: 'network-only',
    });

    const deferredClipSuggestedSearchKeywords = context.apolloClient.query({
      query: graphql(/* GraphQL */ `
        query SearchPreviewPageQuery {
          clipSuggestedSearchKeywords
        }
      `),
      fetchPolicy: 'network-only',
    });

    return {
      deferredClipRecentSearchKeywords: defer(deferredClipRecentSearchKeywords),
      deferredClipSuggestedSearchKeywords: defer(deferredClipSuggestedSearchKeywords),
    };
  },
});

function SearchPreviewPage() {
  const viewTransitionNavigate = useViewTransitionNavigate();

  const handleSubmit = (elem: HTMLFormElement) => {
    const formData = new FormData(elem);
    const q = formData.get('q') as string;

    viewTransitionNavigate({
      to: '/search',
      search: {q},
    });
  };
  const formRef = useRef<HTMLFormElement>(null);

  const {deferredClipRecentSearchKeywords, deferredClipSuggestedSearchKeywords} =
    Route.useLoaderData();

  return (
    <main className="w-full">
      <div className="px-4 pt-[26px]">
        <Link to="/" replace>
          <MdClose className="absolute top-[26px] right-4 z-10 h-6 w-6" />
        </Link>

        <p className="mb-[22px] font-semibold text-lg">어떤 패션 콘텐츠를 찾고 있나요?</p>

        <form
          ref={formRef}
          onSubmit={e => {
            e.preventDefault();
            handleSubmit(e.target as HTMLFormElement);
          }}
        >
          <div
            className="relative mb-1 w-full"
            style={{
              viewTransitionName: 'search-input',
            }}
          >
            <MdSearch
              className="absolute top-0 left-0 mx-3 h-10 w-4 text-muted-foreground"
              color="#fff"
            />
            <Input
              variant="outline"
              type="text"
              autoFocus
              name="q"
              className="bg-white/10 pl-9 outline-none"
              enterKeyHint="search"
            />

            <button type="submit" hidden />
          </div>
        </form>

        <Await promise={deferredClipRecentSearchKeywords}>
          {({data}) => (
            <>
              <p className="mt-7 font-semibold text-[#999] text-[13px]">최근 검색어</p>
              {/* 임시 블러 처리 */}
              <div className="pointer-events-none mt-3 inline-flex select-none flex-wrap gap-2 blur-[3px]">
                {data?.clipRecentSearchKeywords.map((tag: string) => (
                  // 임시 블러처리 아래로 바꾸기
                  // <Link key={tag} to="/search" search={{q: tag}}>
                  //   <Tag>{tag}</Tag>
                  //  </Link>

                  <Tag key={tag}>{tag}</Tag>
                ))}
              </div>
            </>
          )}
        </Await>

        <Await promise={deferredClipSuggestedSearchKeywords}>
          {({data}) => (
            <>
              <p className="mt-7 font-semibold text-[#999] text-[13px]">추천 키워드</p>
              <div className="mt-3 inline-flex flex-wrap gap-2">
                {data?.clipSuggestedSearchKeywords.map(tag => (
                  <Link key={tag} to="/search" search={{q: tag}}>
                    <Tag>{tag}</Tag>
                  </Link>
                ))}
              </div>
            </>
          )}
        </Await>
      </div>
    </main>
  );
}
