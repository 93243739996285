import {AnimatePresence, motion} from 'framer-motion';

import {cn} from '@/lib/utils';
import * as Sentry from '@sentry/react';
import {delay} from 'es-toolkit';
import {useRef, useState} from 'react';
import {MdOutlineDone, MdOutlineTextsms} from 'react-icons/md';
import {Button} from './ui/button';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from './ui/sheet';
import {Textarea} from './ui/textarea';

export const Feedback = () => {
  const [showDoneMessage, setShowDoneMessage] = useState(false);
  const [showError, setShowError] = useState(false);

  const messageRef = useRef<HTMLTextAreaElement>(null);

  const [isSheetOpen, setIsSheetOpen] = useState(false);
  return (
    <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
      <AnimatePresence initial={false}>
        <SheetTrigger asChild>
          <motion.button
            className="absolute right-4 bottom-12 z-30 flex h-10 flex-row items-center rounded-full bg-white shadow-[0px_0px_12px_0px_rgba(0,0,0,0.25)]"
            transition={{duration: 0.3}}
            animate={{width: !showDoneMessage ? 103 : 189}}
          >
            {!showDoneMessage ? (
              <MdOutlineTextsms size={24} color="#111" className="absolute left-4" />
            ) : (
              <MdOutlineDone size={24} color="#111" className="absolute left-4" />
            )}

            <div className="absolute top-2 left-12 flex h-10 w-full flex-row items-center">
              <AnimatePresence>
                {!showDoneMessage ? (
                  <motion.p
                    className="absolute top-0 left-0 font-medium text-[#111] text-[15px]"
                    initial={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 0.3}}
                  >
                    피드백
                  </motion.p>
                ) : null}
              </AnimatePresence>

              {showDoneMessage ? (
                <motion.p
                  className="absolute top-0 left-0 w-[125px] font-medium text-[#111] text-[15px]"
                  initial={{opacity: 1}}
                  exit={{opacity: 0}}
                  transition={{duration: 0.3}}
                >
                  피드백을 보냈습니다.
                </motion.p>
              ) : null}
            </div>
          </motion.button>
        </SheetTrigger>
      </AnimatePresence>

      <SheetContent side="bottom" className="w-full">
        <SheetHeader>
          <SheetTitle>
            서비스에 대한 개선 사항 등 피드백을 자유롭게 보내주세요.
          </SheetTitle>
          <SheetDescription>
            보내주신 의견은 제품 개선 활동에 큰 도움이 됩니다.
          </SheetDescription>
        </SheetHeader>

        <Textarea
          ref={messageRef}
          className={cn('mt-4 min-h-[180px]', showError && 'border-[#E82604]')}
          placeholder="피드백을 입력해 주세요."
        />
        {showError ? (
          <p className="mt-[5px] font-normal text-[#E82604] text-[12px]">
            피드백을 입력해 주세요.
          </p>
        ) : null}

        <Button
          onClick={async () => {
            if (showDoneMessage) {
              return;
            }
            const message = messageRef.current?.value;
            if (!message) {
              setShowError(true);
              return;
            }

            setIsSheetOpen(false);
            setShowDoneMessage(true);

            Sentry.captureFeedback({
              name: `Feedback`,
              message: message,
            });
            await delay(2000);
            setShowDoneMessage(false);
          }}
          size="md"
          className="mt-6 w-full"
        >
          완료
        </Button>
      </SheetContent>
    </Sheet>
  );
};
