import type {MongoAbility, RawRuleOf} from '@casl/ability';
import {createMongoAbility} from '@casl/ability';
import {unpackRules} from '@casl/ability/extra';
import {createContextualCan} from '@casl/react';
import * as Sentry from '@sentry/react';
import {createContext} from 'react';

import {graphql} from '@/gql';
import {apolloClient} from './apollo';

const CASL_RULES_JSON_QUERY = graphql(/* GraphQL */ `
  query CaslRulesJson {
    caslRulesJson
  }
`);

/**
 * apis-next: `@/casl/casl.types.ts`
 */
export type Actions = 'read' | 'manage' | 'write__look_use';
export type SubjectNames =
  | 'Look'
  | 'LookUse'
  | 'LookReport'
  | 'Reaction'
  | 'SelfCampaign'
  | 'CLIP';
export type Subjects = SubjectNames | {__typename?: SubjectNames} | 'all';

export type AppAbilityTuple = [Actions, Subjects];
export type AppAbility = MongoAbility<AppAbilityTuple>;

export const AbilityContext = createContext<AppAbility>(createMongoAbility());

export const Can = createContextualCan(AbilityContext.Consumer);

export const AbilityProvider = AbilityContext.Provider;

export interface AbilityStore {
  ability: AppAbility;
  setAbility: (ability: AppAbility) => void;
}
import {jotaiStore} from '@/store/jotai';
import {atom, useAtomValue} from 'jotai';
import {accessTokenAtom} from './auth';

export const abilityAtom = atom(createMongoAbility<AppAbility>());

export const useAbility = () => {
  return useAtomValue(abilityAtom);
};

export const loadAbility = async (accessToken?: string) => {
  let accessTokenValue: string | null = accessToken ?? null;
  if (!accessTokenValue) {
    accessTokenValue = jotaiStore.get(accessTokenAtom);
  }

  if (!accessTokenValue) {
    return;
  }

  try {
    const {data} = await apolloClient.query({
      query: CASL_RULES_JSON_QUERY,
      context: {
        headers: {
          Authorization: `Bearer ${accessTokenValue}`,
        },
      },
    });
    const ability = createMongoAbility<AppAbility>(
      unpackRules<RawRuleOf<AppAbility>>(JSON.parse(data.caslRulesJson) as any),
      {
        detectSubjectType: (object: any) => {
          if (typeof object === 'string') {
            return object;
          }

          if (typeof object === 'object' && object !== null && '__typename' in object) {
            return object.__typename;
          }

          return 'any';
        },
      }
    );

    jotaiStore.set(abilityAtom, ability);
    return ability;
  } catch (e) {
    Sentry.captureException(e);
  }
};
