import {type UseNavigateResult, useNavigate} from '@tanstack/react-router';
import {flushSync} from 'react-dom';

export const useViewTransitionNavigate = <
  TDefaultFrom extends string = string,
>(): UseNavigateResult<TDefaultFrom> => {
  const navigate = useNavigate();

  return async options => {
    if (document.startViewTransition) {
      document.startViewTransition(() => {
        flushSync(() => {
          navigate(options);
        });
      });
    } else {
      navigate(options);
    }
  };
};
