import Brandazine from '@/assets/brandazine.svg?react';
import {valibotResolver} from '@hookform/resolvers/valibot';
import * as Sentry from '@sentry/react';

import {ampli} from '@/ampli';
import {Button} from '@/components/ui/button';
import {Input, InputError} from '@/components/ui/input';
import {graphql} from '@/gql';
import {cn} from '@/lib/utils';
import {accessTokenAtom, getUserAccountIdFromCookie, useAuthContext} from '@/utils/auth';
import {loadAbility} from '@/utils/casl';
import {useMutation} from '@apollo/client';
import {createFileRoute, redirect, useNavigate} from '@tanstack/react-router';
import {useSetAtom} from 'jotai';
import Cookies from 'js-cookie';
import {useEffect} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {useForm} from 'react-hook-form';
import * as v from 'valibot';

const querySchema = v.object({
  redirect: v.optional(v.string(), '/'),
});

type Query = v.InferInput<typeof querySchema>;

export const Route = createFileRoute('/auth/login')({
  component: LoginPage,
  validateSearch: search => v.parse(querySchema, search),
  loaderDeps: ({search}) => {
    return search;
  },
  beforeLoad: ({context, location}) => {
    if (context.auth.isAuthenticated) {
      const redirectTo = (location.search as Query).redirect || '/';

      return redirect({
        to: redirectTo,
      });
    }
    return context;
  },
});

const loginSchema = v.object({
  loginId: v.string(),
  password: v.string(),
});

const LOGIN_MUTATION = graphql(/* GraphQL */ `
  mutation Login($input: AuthenticateInput!) {
    authenticateV2(input: $input) {
      accessToken
    }
  }
  `);

function LoginPage() {
  const {executeRecaptcha} = useGoogleReCaptcha();

  const navigate = useNavigate();
  const {redirect} = Route.useSearch();

  const {
    register,
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm({
    defaultValues: {
      loginId: '',
      password: '',
    },
    resolver: valibotResolver(loginSchema),
  });

  const setToken = useSetAtom(accessTokenAtom);
  const auth = useAuthContext();

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate({to: redirect || '/'});
    }
  }, [auth.isAuthenticated, navigate]);

  const [login] = useMutation(LOGIN_MUTATION, {
    onCompleted: async data => {
      const ability = await loadAbility(data.authenticateV2.accessToken);
      if (!ability?.can('read', 'CLIP')) {
        setError('loginId', {message: '권한이 없습니다.'});
        Cookies.remove('ua_id');
        Cookies.remove('ua_refresh');
        return;
      }

      const userAccountId = getUserAccountIdFromCookie();
      ampli.identify(userAccountId);
      Sentry.setUser({
        id: userAccountId,
      });
      setToken(data.authenticateV2.accessToken);
    },
    onError: error => {
      console.error(error);
      if (error.message.includes('아이디')) {
        setError('loginId', {message: error.message});
      } else if (error.message.includes('비밀번호')) {
        setError('password', {message: error.message});
      } else {
        setError('loginId', {message: error.message});
      }
    },
  });

  const onSubmit = async (data: v.InferInput<typeof loginSchema>) => {
    if (!executeRecaptcha) {
      alert('리캡챠 토큰 발급 실패');
      window.location.reload();
      return;
    }
    const token = await executeRecaptcha();

    login({
      variables: {
        input: {
          type: 'OTHER',
          token,
          code: data.loginId,
          loginId: data.loginId,
          password: data.password,
        },
      },
    });
  };

  return (
    <main className="h-svh w-full overflow-hidden bg-[#1A1B1C]">
      <p className="mt-[196px] mb-[60px] text-center font-bold text-[28px] text-white opacity-[96]">
        Creator Clip
      </p>

      <form className="px-4" onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder="브랜더진 계정"
          {...register('loginId')}
          className={cn(errors.loginId?.message && 'border border-[#E82604]')}
        />
        <InputError message={errors.loginId?.message} />

        <Input
          type="password"
          autoComplete="off"
          placeholder="비밀번호"
          className={cn('mt-4', errors.password?.message && 'border border-[#E82604]')}
          {...register('password')}
        />
        <InputError message={errors.password?.message} />

        <Button type="submit" size="md" className="mt-8 w-full">
          로그인
        </Button>
      </form>
      <Brandazine className="absolute bottom-10 z-10 w-full" />
    </main>
  );
}
