import {AiOutlineLoading3Quarters} from 'react-icons/ai';

import {cn} from '@/lib/utils';
import {Link} from '@tanstack/react-router';
import {useVirtualizer} from '@tanstack/react-virtual';
import {type HTMLAttributes, Suspense, forwardRef, useMemo, useRef} from 'react';

import {graphql} from '@/gql';
import {useInfiniteScroll} from '@/hooks/use-infinite-scroll';
import {usePreloadImages} from '@/hooks/use-preload-images';
import {dynamicChunk} from '@/utils/dynamicChunk';
import {type FragmentOf, readFragment} from 'gql.tada';

export const GridClipImageContainerFallback = () => {
  return (
    <div className={`grid__dynamic-gallery--set-1 gap-[1px]`}>
      {Array.from({length: 12}, (_, index) => (
        <div
          key={index}
          className="aspect-square animate-pulse bg-white/10"
          style={{
            gridArea: `i${index + 1}`,
          }}
        />
      ))}
    </div>
  );
};

const clipImageFragment = graphql(/* GraphQL */ `
  fragment ClipImageFragment on ClipImageDto {
    id
    smallImageUrl
    originalImageUrl
  }
`);

export interface GridClipImageContainerProps {
  searchQueryId: string;
  $data: FragmentOf<typeof clipImageFragment>[];
  className?: string;
  chunkSize?: number;
  onEndReached: () => void;
  fetching: boolean;
}

interface GridClipImageContainerInternalProps extends HTMLAttributes<HTMLDivElement> {
  isChunkDataFullyLoaded: boolean;
  data: {
    id: string;
    smallImageUrl: string;
  }[];
  virtualItemsLength: number;
  index: number;
  searchQueryId: string;
}

const GridClipImageContainerInternal = forwardRef<
  HTMLDivElement,
  GridClipImageContainerInternalProps
>(
  (
    {data, isChunkDataFullyLoaded, virtualItemsLength, index, searchQueryId, ...props},
    ref
  ) => {
    usePreloadImages(data.map(item => item.smallImageUrl));

    return (
      <div ref={ref} {...props}>
        {data.map((item, itemIndex) => {
          const isLastItem =
            virtualItemsLength - 1 === index && data.length - 1 === itemIndex;
          ``;
          return (
            <Link
              key={itemIndex}
              to={`/detail/${item.id}`}
              search={{searchQueryId}}
              className={cn('aspect-square')}
              style={{
                gridArea: `i${itemIndex + 1}`,
              }}
            >
              <img
                id={isLastItem ? 'last-item' : undefined}
                src={item.smallImageUrl}
                alt={item.id}
                className="h-full w-full object-cover"
              />
            </Link>
          );
        })}
      </div>
    );
  }
);

// @see https://www.figma.com/design/ajIq5pw2ZA8HEfj2nJ0Zm2/CLIP?node-id=561-8116&m=dev
// chunk별 아이템 개수
const CHUNK_TEMPLATE = [12, 12, 9, 12, 12, 9];

export const GridClipImageContainer = ({
  searchQueryId,
  $data,
  className,
  chunkSize = 12,
  onEndReached,
}: GridClipImageContainerProps) => {
  const data = readFragment(clipImageFragment, $data);
  usePreloadImages(data.map(item => item.smallImageUrl));

  const parentRef = useRef<HTMLDivElement>(null);

  const chunkedData = useMemo(
    () => dynamicChunk(data, index => CHUNK_TEMPLATE[index % CHUNK_TEMPLATE.length]),
    [data]
  );

  const rowVirtualizer = useVirtualizer({
    count: chunkedData.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 1175, // width 720px 기준, 12개의 리스트 높이
    gap: 1,
  });

  useInfiniteScroll({
    target: document.getElementById('last-item'),
    onEndReached,
  });

  const virtualItems = rowVirtualizer.getVirtualItems();

  return (
    <div ref={parentRef} className={cn('scrollbar-hide h-full overflow-auto', className)}>
      <div
        className="relative"
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
        }}
      >
        {virtualItems.map((virtualRow, index) => {
          const rowIndex = virtualRow.index;
          const rowChunk = chunkedData[rowIndex];
          const gridTemplateSet = (rowIndex % CHUNK_TEMPLATE.length) + 1;
          const isChunkDataFullyLoaded = chunkSize === rowChunk.length;

          return (
            <Suspense key={virtualRow.key}>
              <GridClipImageContainerInternal
                ref={rowVirtualizer.measureElement}
                data-index={virtualRow.index}
                className={cn(
                  'absolute w-full gap-[1px]',
                  `grid__dynamic-gallery--set-${gridTemplateSet}`
                )}
                style={{
                  transform: `translateY(${virtualRow.start}px)`,
                }}
                data={rowChunk}
                isChunkDataFullyLoaded={isChunkDataFullyLoaded}
                virtualItemsLength={virtualItems.length}
                index={index}
                searchQueryId={searchQueryId}
              />
            </Suspense>
          );
        })}
      </div>

      <div className="flex h-20 items-center justify-center">
        <AiOutlineLoading3Quarters size={24} className="animate-spin" />
      </div>
    </div>
  );
};

GridClipImageContainer.fragments = clipImageFragment;
