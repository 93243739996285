import {useEffect} from 'react';

interface UseInfiniteScrollOptions {
  onEndReached: () => void;
  options?: IntersectionObserverInit;
  target: HTMLElement | null;
}

export const useInfiniteScroll = ({
  target,
  onEndReached,
  options,
}: UseInfiniteScrollOptions) => {
  const onScroll: IntersectionObserverCallback = entries => {
    if (!entries[0].isIntersecting) {
      return;
    }

    onEndReached();
  };

  useEffect(() => {
    const observer = new IntersectionObserver(onScroll, options);
    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
      observer.disconnect();
    };
  }, [target]);
};
