import {registerAmplitudeMap} from '@/ampli/event-schema';
import {
  GridClipImageContainer,
  GridClipImageContainerFallback,
} from '@/components/grid-clip-image-container';
import {Button} from '@/components/ui/button';
import {Input} from '@/components/ui/input';
import {graphql} from '@/gql';
import {NetworkStatus, useSuspenseQuery} from '@apollo/client';
import {ErrorBoundary} from '@sentry/react';
import {Link, createFileRoute, useRouter} from '@tanstack/react-router';
import {Suspense, startTransition, useState} from 'react';
import {MdArrowBackIosNew, MdSearch} from 'react-icons/md';
import {useDebounce} from 'use-debounce';
import * as v from 'valibot';
import {DetailPage} from './detail.$clipImageId';

const querySchema = v.object({
  q: v.optional(v.string(), ''),
});

registerAmplitudeMap.set('/search', {
  schema: querySchema,
  eventName: 'VIEW_SEARCH_PAGE',
});

export const Route = createFileRoute('/search')({
  component: SearchPage,
  validateSearch: search => v.parse(querySchema, search),

  loaderDeps: ({search}) => {
    return search;
  },
  staleTime: Number.POSITIVE_INFINITY,
});

const SEARCH_QUERY = graphql(
  /* GraphQL */ `
  query SearchQuery($query: String, $limit: Int!, $offset: Int!, $searchQueryId: UUID) {
    clipImages(query: $query, limit: $limit, offset: $offset, searchQueryId: $searchQueryId) @connection(key: "clipImages") {
      searchQueryId
      items {
        id
        smallImageUrl
        ...ClipImageFragment

        # preload
        ...DetailProfileFragment
      }
    }
  }
`,
  [GridClipImageContainer.fragments, DetailPage.fragments]
);

const SearchResult = ({query}: {query: string}) => {
  const {data, error, fetchMore, networkStatus} = useSuspenseQuery(SEARCH_QUERY, {
    variables: {
      query,
      limit: 24,
      offset: 0,
    },
  });

  const [isFetching, setIsFetching] = useState(false);

  if (error) {
    throw error;
  }

  const items = data?.clipImages?.items ?? [];

  return (
    <GridClipImageContainer
      $data={items}
      fetching={isFetching}
      searchQueryId={data.clipImages.searchQueryId}
      onEndReached={() => {
        if (networkStatus === NetworkStatus.ready) {
          setIsFetching(true);

          startTransition(() => {
            fetchMore({
              variables: {
                query: undefined,
                searchQueryId: data.clipImages.searchQueryId,
                offset: items.length,
                limit: 12,
              },
            }).finally(() => {
              setIsFetching(false);
            });
          });
        }
      }}
    />
  );
};

function SearchPage() {
  const query = Route.useSearch({select: s => s.q});
  const [deferredQuery] = useDebounce(query, 200);

  const navigate = Route.useNavigate();
  const {history} = useRouter();

  return (
    <div className="flex h-svh w-full flex-col overflow-hidden">
      <div className="flex flex-row items-center gap-[9px] px-4 pt-[13px] pb-5">
        <button onClick={() => history.back()}>
          <MdArrowBackIosNew size={24} />
        </button>
        <div
          className="relative w-full"
          style={{
            viewTransitionName: 'search-input',
          }}
        >
          <MdSearch
            className="absolute top-0 left-0 mx-3 h-10 w-4 text-muted-foreground"
            color="#fff"
          />
          <Input
            autoFocus
            variant="outline"
            className="bg-white/10 pl-9 outline-none"
            defaultValue={query}
            onChange={e => {
              navigate({search: {q: e.target.value}, replace: true});
            }}
          />
        </div>
      </div>

      <ErrorBoundary
        fallback={
          <div className="flex flex-1 flex-col items-center justify-center px-7">
            <p className="text-center font-semibold text-base text-white">
              다른 검색어를 입력해 주세요
            </p>
            <p className="mt-3 whitespace-pre text-center font-normal text-muted-foreground text-sm text-white">
              {
                '검색 결과를 찾을 수 없는 검색어입니다.\n새로운 검색어로 다시 검색해 주세요.'
              }
            </p>

            <Link to="/search-preview" replace>
              <Button variant="secondary" size="md" className="mt-8 w-full">
                다시 검색하기
              </Button>
            </Link>
          </div>
        }
      >
        <Suspense fallback={<GridClipImageContainerFallback />}>
          <SearchResult query={deferredQuery} />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
