import { jotaiStore } from "@/store/jotai";
import { atom } from "jotai";
import type { ObjectSchema } from "valibot";

/**
 * 해당 페이지에서 아래와 같은 코드를 입력하면 자동으로 amplitude에 이벤트가 전송됩니다.
 * @example
 * registerAmplitudeMap.set('/search', {
    schema: searchPageSchema,
    eventName: 'VIEW_SEARCH_PAGE',
 * });
 */
export const registerAmplitudeMap = new Map<
  string,
  {
    schema?: ObjectSchema<any, undefined>;
    eventName: string;
  }
>();

// amplitude에 보내기 위해 이전 history를 저장합니다.
const prevHistoryAtom = atom<string | null>(null);
prevHistoryAtom.debugLabel = "prevHistoryAtom";

export const setPrevAmplitudeHistory = (update: string) => {
  jotaiStore.set(prevHistoryAtom, update);
};

export const getPrevAmplitudeHistory = () => jotaiStore.get(prevHistoryAtom);
