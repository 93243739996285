import { jotaiStore } from "@/store/jotai";
import { accessTokenAtom, clearAuthState } from "@/utils/auth";
import { env } from "@/utils/env";
import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";

const httpLink = new HttpLink({
  uri: env.graphqlUrl,
  credentials: "include",
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error, _operation) => !!error,
  },
});

const authLink = setContext(async (_, { headers }) => {
  const accessToken = await jotaiStore.get(accessTokenAtom);

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      if (extensions?.code === "UNAUTHORIZED") {
        clearAuthState();
      }
    });
  }
});

export const apolloClient = new ApolloClient({
  link: from([authLink, errorLink, retryLink, httpLink]),
  defaultOptions: {
    mutate: {
      errorPolicy: "all",
    },
    watchQuery: {
      errorPolicy: "all",
    },
    query: {
      notifyOnNetworkStatusChange: true,
      errorPolicy: "all",
    },
  },
  connectToDevTools: import.meta.env.DEV,
  cache: new InMemoryCache({
    typePolicies: {
      PaginatedClipImageSearchResultDto: {
        keyFields: ["searchQueryId"],
        fields: {
          items: {
            keyArgs: ["query"],
            merge: (existing = [], incoming = []) => {
              const merged = [...existing];
              for (const item of incoming) {
                merged.push(item);
              }
              return merged;
            },
          },
        },
      },
      Query: {
        fields: {
          clipImages: {
            keyArgs: ["query"],
          },
          clipImagesByCuratorId: {
            keyArgs: ["curatorId"],
          },
        },
      },
    },
  }),
});
