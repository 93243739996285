export const dynamicChunk = <T>(
  array: readonly T[] | T[],
  sizeFn: (index: number) => number
) => {
  const result: T[][] = [];
  let i = 0;

  while (i < array.length) {
    // Determine the chunk size based on the current index
    const size = sizeFn(result.length);
    result.push(array.slice(i, i + size));
    i += size;
  }

  return result;
};
