/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SearchPreviewImport } from './routes/search-preview'
import { Route as SearchImport } from './routes/search'
import { Route as IndexImport } from './routes/index'
import { Route as ProfileCuratorIdImport } from './routes/profile.$curatorId'
import { Route as DetailClipImageIdImport } from './routes/detail.$clipImageId'
import { Route as AuthTokenImport } from './routes/auth/token'
import { Route as AuthLoginImport } from './routes/auth/login'

// Create/Update Routes

const SearchPreviewRoute = SearchPreviewImport.update({
  id: '/search-preview',
  path: '/search-preview',
  getParentRoute: () => rootRoute,
} as any)

const SearchRoute = SearchImport.update({
  id: '/search',
  path: '/search',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const ProfileCuratorIdRoute = ProfileCuratorIdImport.update({
  id: '/profile/$curatorId',
  path: '/profile/$curatorId',
  getParentRoute: () => rootRoute,
} as any)

const DetailClipImageIdRoute = DetailClipImageIdImport.update({
  id: '/detail/$clipImageId',
  path: '/detail/$clipImageId',
  getParentRoute: () => rootRoute,
} as any)

const AuthTokenRoute = AuthTokenImport.update({
  id: '/auth/token',
  path: '/auth/token',
  getParentRoute: () => rootRoute,
} as any)

const AuthLoginRoute = AuthLoginImport.update({
  id: '/auth/login',
  path: '/auth/login',
  getParentRoute: () => rootRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/search': {
      id: '/search'
      path: '/search'
      fullPath: '/search'
      preLoaderRoute: typeof SearchImport
      parentRoute: typeof rootRoute
    }
    '/search-preview': {
      id: '/search-preview'
      path: '/search-preview'
      fullPath: '/search-preview'
      preLoaderRoute: typeof SearchPreviewImport
      parentRoute: typeof rootRoute
    }
    '/auth/login': {
      id: '/auth/login'
      path: '/auth/login'
      fullPath: '/auth/login'
      preLoaderRoute: typeof AuthLoginImport
      parentRoute: typeof rootRoute
    }
    '/auth/token': {
      id: '/auth/token'
      path: '/auth/token'
      fullPath: '/auth/token'
      preLoaderRoute: typeof AuthTokenImport
      parentRoute: typeof rootRoute
    }
    '/detail/$clipImageId': {
      id: '/detail/$clipImageId'
      path: '/detail/$clipImageId'
      fullPath: '/detail/$clipImageId'
      preLoaderRoute: typeof DetailClipImageIdImport
      parentRoute: typeof rootRoute
    }
    '/profile/$curatorId': {
      id: '/profile/$curatorId'
      path: '/profile/$curatorId'
      fullPath: '/profile/$curatorId'
      preLoaderRoute: typeof ProfileCuratorIdImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/search': typeof SearchRoute
  '/search-preview': typeof SearchPreviewRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/token': typeof AuthTokenRoute
  '/detail/$clipImageId': typeof DetailClipImageIdRoute
  '/profile/$curatorId': typeof ProfileCuratorIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/search': typeof SearchRoute
  '/search-preview': typeof SearchPreviewRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/token': typeof AuthTokenRoute
  '/detail/$clipImageId': typeof DetailClipImageIdRoute
  '/profile/$curatorId': typeof ProfileCuratorIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/search': typeof SearchRoute
  '/search-preview': typeof SearchPreviewRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/token': typeof AuthTokenRoute
  '/detail/$clipImageId': typeof DetailClipImageIdRoute
  '/profile/$curatorId': typeof ProfileCuratorIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/search'
    | '/search-preview'
    | '/auth/login'
    | '/auth/token'
    | '/detail/$clipImageId'
    | '/profile/$curatorId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/search'
    | '/search-preview'
    | '/auth/login'
    | '/auth/token'
    | '/detail/$clipImageId'
    | '/profile/$curatorId'
  id:
    | '__root__'
    | '/'
    | '/search'
    | '/search-preview'
    | '/auth/login'
    | '/auth/token'
    | '/detail/$clipImageId'
    | '/profile/$curatorId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  SearchRoute: typeof SearchRoute
  SearchPreviewRoute: typeof SearchPreviewRoute
  AuthLoginRoute: typeof AuthLoginRoute
  AuthTokenRoute: typeof AuthTokenRoute
  DetailClipImageIdRoute: typeof DetailClipImageIdRoute
  ProfileCuratorIdRoute: typeof ProfileCuratorIdRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  SearchRoute: SearchRoute,
  SearchPreviewRoute: SearchPreviewRoute,
  AuthLoginRoute: AuthLoginRoute,
  AuthTokenRoute: AuthTokenRoute,
  DetailClipImageIdRoute: DetailClipImageIdRoute,
  ProfileCuratorIdRoute: ProfileCuratorIdRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/search",
        "/search-preview",
        "/auth/login",
        "/auth/token",
        "/detail/$clipImageId",
        "/profile/$curatorId"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/search": {
      "filePath": "search.tsx"
    },
    "/search-preview": {
      "filePath": "search-preview.tsx"
    },
    "/auth/login": {
      "filePath": "auth/login.tsx"
    },
    "/auth/token": {
      "filePath": "auth/token.tsx"
    },
    "/detail/$clipImageId": {
      "filePath": "detail.$clipImageId.tsx"
    },
    "/profile/$curatorId": {
      "filePath": "profile.$curatorId.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
