import {Link, createFileRoute} from '@tanstack/react-router';

import {StickyHeader} from '@/components/ui/sticky-header';
import {graphql} from '@/gql';
import {SuspenseImage} from '@/hooks/use-preload-images';
import {NetworkStatus, useFragment, useSuspenseQuery} from '@apollo/client';
import {Avatar, AvatarFallback, AvatarImage} from '@radix-ui/react-avatar';

import {registerAmplitudeMap} from '@/ampli/event-schema';
import {BrandazineIcon} from '@/components/brandazine-icon';
import {
  GridClipImageContainer,
  GridClipImageContainerFallback,
} from '@/components/grid-clip-image-container';
import {Button} from '@/components/ui/button';
import {numberToHangulMixed} from '@/utils/numberToHangulMixed';
import {ErrorBoundary} from '@sentry/react';
import {Suspense, startTransition, useState} from 'react';
import * as v from 'valibot';

const DETAIL_PROFILE_FRAGMENT = graphql(
  /* GraphQL */ `
  fragment DetailProfileFragment on ClipImageDto {
    id
    smallImageUrl
    originalImageUrl
    profilePictureUrl
    curatorInfo {
      instagramUsername
      instagramFollowerCount
    }
    bzineData {
      curatorId
    }
  }
`
);

const DETAIL_PROFILE_QUERY = graphql(
  /* GraphQL */ `
  query DetailProfileQuery($clipImageId: UUID!) {
    clipImage(id: $clipImageId) {
      id
      ...DetailProfileFragment
    }
  }
`,
  [DETAIL_PROFILE_FRAGMENT]
);

const querySchema = v.object({
  searchQueryId: v.optional(v.string(), ''),
});

registerAmplitudeMap.set('/detail/$clipImageId', {
  schema: querySchema,
  eventName: 'VIEW_SEARCH_DETAIL_PAGE',
});

export const Route = createFileRoute('/detail/$clipImageId')({
  component: DetailPage,
  validateSearch: search => v.parse(querySchema, search),
  loaderDeps: ({search}) => {
    return search;
  },
  loader: async ({context, params}) => {
    const data = context.apolloClient.readFragment({
      id: `ClipImageDto:${params.clipImageId}`,
      fragment: DETAIL_PROFILE_FRAGMENT,
    });
    if (data) {
      return {};
    }

    // fill fragment
    await context.apolloClient.query({
      query: DETAIL_PROFILE_QUERY,
      variables: {
        clipImageId: params.clipImageId,
      },
    });

    return {};
  },
});

const CLIP_SIMILAR_IMAGES_QUERY = graphql(
  /* GraphQL */ `
  query ClipSimilarImagesQuery($searchQueryId: UUID!, $clipImageId: UUID!, $limit: Int!, $offset: Int!) {
    clipSimilarImages(searchQueryId: $searchQueryId, clipImageId: $clipImageId, limit: $limit, offset: $offset) @connection(key: "clipSimilarImages") {
      searchQueryId
      items {
        id
        smallImageUrl
        ...ClipImageFragment
      }
    }
  }
`,
  [GridClipImageContainer.fragments]
);

const SimilarImages = ({
  searchQueryId,
  clipImageId,
}: {
  searchQueryId: string;
  clipImageId: string;
}) => {
  const {
    data: similarImagesData,
    networkStatus,
    error,
    fetchMore,
  } = useSuspenseQuery(CLIP_SIMILAR_IMAGES_QUERY, {
    variables: {
      searchQueryId,
      clipImageId,
      limit: 24,
      offset: 0,
    },
  });

  const [isFetching, setIsFetching] = useState(false);

  if (error) {
    throw error;
  }

  return (
    <GridClipImageContainer
      searchQueryId={similarImagesData.clipSimilarImages.searchQueryId}
      $data={similarImagesData.clipSimilarImages.items ?? []}
      onEndReached={() => {
        if (networkStatus === NetworkStatus.ready) {
          setIsFetching(true);
          startTransition(() => {
            fetchMore({
              variables: {
                searchQueryId: similarImagesData.clipSimilarImages.searchQueryId,
                clipImageId,
                offset: similarImagesData.clipSimilarImages.items?.length ?? 0,
                limit: 12,
              },
            }).finally(() => {
              setIsFetching(false);
            });
          });
        }
      }}
      fetching={isFetching}
    />
  );
};

export function DetailPage() {
  const {clipImageId} = Route.useParams();
  const {searchQueryId} = Route.useSearch();

  const {data, complete} = useFragment({
    fragment: DETAIL_PROFILE_FRAGMENT,
    from: {
      __typename: 'ClipImageDto',
      id: clipImageId,
    },
  });

  if (!complete) {
    return <div>Loading...</div>;
  }

  const {smallImageUrl, profilePictureUrl, curatorInfo, bzineData} = data;

  const isBrandazine = Boolean(bzineData);

  const ConditionalLink = isBrandazine ? Link : 'div';

  return (
    <main>
      <StickyHeader />

      <SuspenseImage
        src={smallImageUrl ?? ''}
        className="w-full object-cover"
        fallback={<div className="h-[232px] w-full animate-pulse bg-white/10" />}
      />
      <ConditionalLink
        to="/profile/$curatorId"
        params={{curatorId: String(bzineData?.curatorId)}}
        className="block p-4"
      >
        <div className="flex flex-row items-center gap-3">
          <Avatar className="h-[60px] w-[60px]">
            <AvatarImage
              src={profilePictureUrl ?? ''}
              className="h-full w-full rounded-full"
            />
            <AvatarFallback className="font-bold text-[32px] text-[rgba(255,255,255,0.10)]">
              {curatorInfo?.instagramUsername?.[0]?.toUpperCase()}
            </AvatarFallback>
          </Avatar>

          <div>
            <div className="flex flex-row items-center gap-[6px]">
              <p className="font-semibold text-[16px]">{curatorInfo.instagramUsername}</p>

              {isBrandazine ? <BrandazineIcon /> : null}
            </div>
            <p className="font-normal text-[13px] text-gray-500">
              팔로워{' '}
              <strong className="font-semibold">
                {numberToHangulMixed(curatorInfo.instagramFollowerCount ?? 0)}
              </strong>
            </p>
          </div>
        </div>
      </ConditionalLink>

      <hr className="border-white/10 border-b" />
      <p className="px-4 pt-5 pb-4 font-semibold text-[14px]">유사한 이미지</p>

      <ErrorBoundary
        fallback={({resetError}) => (
          <div className="flex flex-1 flex-col items-center justify-center px-7">
            <p className="text-center font-semibold text-base text-white">
              오류가 발생했습니다.
            </p>

            <Button
              onClick={resetError}
              variant="secondary"
              size="md"
              className="mt-8 w-full"
            >
              다시 시도하기
            </Button>
          </div>
        )}
      >
        <Suspense fallback={<GridClipImageContainerFallback />}>
          <SimilarImages searchQueryId={searchQueryId} clipImageId={clipImageId} />
        </Suspense>
      </ErrorBoundary>
    </main>
  );
}

DetailPage.fragments = DETAIL_PROFILE_FRAGMENT;
