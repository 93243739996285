import {registerAmplitudeMap} from '@/ampli/event-schema';
import {BrandazineIcon} from '@/components/brandazine-icon';
import {
  GridClipImageContainer,
  GridClipImageContainerFallback,
} from '@/components/grid-clip-image-container';
import {Avatar, AvatarFallback, AvatarImage} from '@/components/ui/avatar';
import {Sheet, SheetContent} from '@/components/ui/sheet';
import {StickyHeader} from '@/components/ui/sticky-header';
import {graphql} from '@/gql';
import {numberToHangulMixed} from '@/utils/numberToHangulMixed';
import {NetworkStatus, useSuspenseQuery} from '@apollo/client';
import {createFileRoute} from '@tanstack/react-router';
import {overlay} from 'overlay-kit';
import {Suspense, startTransition, useState} from 'react';
import {MdArrowForwardIos} from 'react-icons/md';

registerAmplitudeMap.set('/profile/$curatorId', {
  eventName: 'VIEW_PROFILE_PAGE',
});

const PROFILE_QUERY = graphql(
  /* GraphQL */ `
  query ProfileQuery($curatorId: Int!) {
    clipProfile(curatorId: $curatorId) {
      id
      profilePictureUrl
      curatorInfo {
        instagramUsername
        instagramFollowerCount
      }
      bzineData {
        curatorId
      }
    }
  }
`
);

const CLIP_IMAGES_BY_CURATOR_ID_QUERY = graphql(
  /* GraphQL */ `
  query ClipImagesByCuratorIdQuery($curatorId: Int!, $limit: Int!, $offset: Int!, $searchQueryId: UUID) {
    clipImagesByCuratorId(curatorId: $curatorId, limit: $limit, offset: $offset, searchQueryId: $searchQueryId) {
      searchQueryId
      items {
        id
        smallImageUrl
        ...ClipImageFragment
      }
    }
  },
`,
  [GridClipImageContainer.fragments]
);

export const Route = createFileRoute('/profile/$curatorId')({
  component: ProfilePage,
  loader: async ({context, params}) => {
    const {data} = await context.apolloClient.query({
      query: PROFILE_QUERY,
      variables: {
        curatorId: Number(params.curatorId),
      },
    });

    return {
      clipProfile: data.clipProfile,
    };
  },
});

const ClipImagesByCuratorId = ({
  curatorId,
}: {
  curatorId: number;
}) => {
  const {data, networkStatus, error, fetchMore} = useSuspenseQuery(
    CLIP_IMAGES_BY_CURATOR_ID_QUERY,
    {
      variables: {
        curatorId,
        limit: 24,
        offset: 0,
      },
    }
  );

  const [isFetching, setIsFetching] = useState(false);

  if (error) {
    throw error;
  }

  return (
    <GridClipImageContainer
      searchQueryId={data.clipImagesByCuratorId.searchQueryId}
      $data={data.clipImagesByCuratorId.items ?? []}
      onEndReached={() => {
        if (networkStatus === NetworkStatus.ready) {
          setIsFetching(true);
          startTransition(() => {
            fetchMore({
              variables: {
                curatorId,
                searchQueryId: data.clipImagesByCuratorId.searchQueryId,
                offset: data.clipImagesByCuratorId.items?.length ?? 0,
                limit: 12,
              },
            }).finally(() => {
              setIsFetching(false);
            });
          });
        }
      }}
      fetching={isFetching}
    />
  );
};

function ProfilePage() {
  const {curatorId} = Route.useParams();
  const {clipProfile} = Route.useLoaderData();

  const curatorInfo = clipProfile.curatorInfo;
  return (
    <main>
      <StickyHeader />

      <div className="flex flex-col items-center justify-center pt-[96px] pb-[36px]">
        <Avatar className="h-[108px] w-[108px]">
          <AvatarImage
            src={clipProfile.profilePictureUrl ?? ''}
            className="h-full w-full rounded-full"
          />
          <AvatarFallback className="font-bold text-[48px] text-[rgba(255,255,255,0.10)]">
            {curatorInfo?.instagramUsername?.[0]?.toUpperCase()}
          </AvatarFallback>
        </Avatar>

        <button
          onClick={() => {
            overlay.open(({isOpen, close}) => (
              <Sheet open={isOpen} onOpenChange={open => !open && close()}>
                <SheetContent>
                  <p className="text-[14px] leading-[21px] opacity-[96]">
                    브랜더진에 가입된 큐레이터입니다.
                  </p>
                </SheetContent>
              </Sheet>
            ));
          }}
          className="mt-5 flex flex-row items-center gap-1"
        >
          <p className="font-semibold text-[20px] text-[rgba(255,255,255,0.96)]">
            {curatorInfo?.instagramUsername}
          </p>

          <BrandazineIcon />
        </button>

        <a
          href={`https://www.instagram.com/${curatorInfo?.instagramUsername}`}
          target="_blank"
          rel="noreferrer noopener"
          className="mt-[10px] flex h-7 flex-row items-center gap-[6px] rounded-lg bg-[rgba(255,255,255,0.10)] px-3 transition-opacity duration-200 hover:opacity-80"
        >
          <p className="font-medium text-[12px] text-white">
            @{curatorInfo?.instagramUsername} • 팔로워{' '}
            {numberToHangulMixed(curatorInfo?.instagramFollowerCount ?? 0)}
          </p>

          <MdArrowForwardIos size={12} />
        </a>
      </div>

      <Suspense fallback={<GridClipImageContainerFallback />}>
        <ClipImagesByCuratorId curatorId={Number(curatorId)} />
      </Suspense>
    </main>
  );
}
