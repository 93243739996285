import Brandazine from '@/assets/brandazine.svg?react';
import ClipLogo from '@/assets/clip-logo.svg?react';
import waveAnimation from '@/assets/lotties/wave.json';
import {Input} from '@/components/ui/input';
import {Tag} from '@/components/ui/tag';
import {graphql} from '@/gql';
import {Await, Link, createFileRoute, defer} from '@tanstack/react-router';
import Lottie from 'lottie-react';
import {MdSearch} from 'react-icons/md';

export const Route = createFileRoute('/')({
  component: MainPage,
  loader: ({context}) => {
    const deferredQuery = context.apolloClient.query({
      query: graphql(/* GraphQL */ `
        query HomePageQuery {
          clipSuggestedSearchKeywords
        }
      `),
    });

    return {
      deferredQuery: defer(deferredQuery),
    };
  },
});

function MainPage() {
  const {deferredQuery} = Route.useLoaderData();
  return (
    <main className="h-svh w-full overflow-hidden">
      <div className="relative z-10 flex h-full flex-col items-center justify-center px-4">
        <ClipLogo className="z-10" />
        <Link to="/search-preview" className="relative z-10 mt-[100px] w-full">
          <MdSearch
            className="absolute top-0 left-0 mx-3 h-10 w-4 text-muted-foreground"
            color="#fff"
          />
          <Input
            variant="outline"
            className="pointer-events-none bg-white/10 pl-9 outline-none"
          />
        </Link>
        <Await promise={deferredQuery}>
          {({data}) => (
            <div className="z-20 mt-10 inline-flex flex-wrap justify-center gap-2">
              {data?.clipSuggestedSearchKeywords.map(tag => (
                <Link key={tag} to="/search" search={{q: tag}}>
                  <Tag>{tag}</Tag>
                </Link>
              ))}
            </div>
          )}
        </Await>
        <Brandazine className="absolute bottom-10 z-10 w-full" />

        <Lottie
          animationData={waveAnimation}
          loop={true}
          className="pointer-events-none absolute bottom-0 z-0 w-full"
        />
      </div>
    </main>
  );
}
